<template>
  <div class="dialog">
    <div class="dialog-header">
      <div class="row">
        <div class="col">
          <p class="dialog-title text-uppercase mb-0">Edit School</p>
        </div>
      </div>
    </div>
    <div class="dialog-content"
         style="overflow-y: auto;"
         v-bind:style="{height: contentHeightOverride}"
    >
      <div class="row">
        <div class="col-12 col-md-4 text-center">
          <div class="default-logos">
            <span>Choose a default logo or upload an image:</span>
            <div class="logos">
              <span  v-for="(img, index) in defaultLogos"
                        :key="index"
                        :class="{active: img.asset_url === currentLogo}"
                        class="position-relative ml-1 mr-1"
                        @click="useDefaultLogo(img.asset_url)"
              >
                <b-img :src="img.asset_url"
                      v-bind="mainProps"
                      rounded="0"
                ></b-img>
              </span>
            </div>
          </div>
            <div v-if="(initialLogo !== null)"
                 class="photo-placeholder">
                <b-button class="m-2 btn-h3-xs"
                          variant="success"
                          @click="resetLogo"
                >
                    Remove Logo
                </b-button>

                <img class="current-img img-fluid"
                     :src="initialLogo"/>
            </div>
            <croppa v-if="showCroppa && (initialLogo === null)"
                   :key="timestamp"
                   v-model="file"
                   accept=".jpeg,.png,.jpg"
                   auto-sizing
                   :width="200"
                   :height="200"
                   placeholder-color="#068bcd"
                   :placeholder="placeholder"
                   :placeholder-font-size="12"
                   :prevent-white-space="false"
                   canvas-color="#fff"
                   remove-button-color="red"
                   :remove-button-size="25"
                   :show-loading="false"
                   :loading-size="25"
                   @file-type-mismatch="onFileTypeMismatch"
                   @new-image="imageUpdate('new')"
                   @image-remove="imageUpdate('removed')">
                  <img class="current-logo"
                       v-if="currentLogo"
                       slot="initial"
                       :src="currentLogo" />
            </croppa>
            <div class="buttons" v-if="initialLogo === null">
              <i class="fas fa-search-plus icon"
                  @click="file.zoomIn()"
              >
              </i>
              <i class="fas fa-search-minus icon"
                  @click="file.zoomOut()">
              </i>
              <i class="fas fa-save icon"
                  :class="{'hide-button': hideButton}"
                  @click="uploadFile">
              </i>
            </div>
        </div>
        <div class="col-12 col-md-8">
          <b-form class="mt-3">
            <b-form-group>
                <b-form-input id="schoolName"
                              type="text"
                              aria-describedby="schoolName"
                              autocomplete="off"
                              placeholder="School Name*"
                              :state="!$v.form.schoolName.$error"
                              @input="$v.form.schoolName.$touch()"
                              v-model="form.schoolName"
                              @focusout="sanitizeWhitespace('schoolName',
                                form)"
                >
                </b-form-input>
                <b-form-invalid-feedback id="schoolName">
                  <span v-if="!$v.form.schoolName.required">
                    School Name is required
                  </span>
                  <span v-if="!$v.form.schoolName.safeSchoolName">
                    School Name not allowed.
                  </span>
                </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <b-form-input id="address1"
                            type="text"
                            aria-describedby="address1"
                            autocomplete="off"
                            placeholder="Bldg name, Street name/no."
                            :state="!$v.form.address1.$error"
                            @input="$v.form.address1.$touch()"
                            v-model="form.address1"
                            @focusout="sanitizeWhitespace('address1',
                              form)">
              </b-form-input>
              <b-form-invalid-feedback id="address1">
                <span v-if="!$v.form.address1.safeAddress1">
                  Address not allowed.
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <b-form-input id="address2"
                            type="text"
                            aria-describedby="address2"
                            autocomplete="off"
                            placeholder="Block no. , Area Name"
                            :state="!$v.form.address2.$error"
                            @input="$v.form.address2.$touch()"
                            v-model="form.address2"
                            @focusout="sanitizeWhitespace('address2',
                              form)">
              </b-form-input>
              <b-form-invalid-feedback id="address2">
                <span v-if="!$v.form.address2.safeAddress2">
                  Address not allowed.
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <v-select class="country-select selection"
                        :class="$v.form.country.$error ?  'invalid' : 'valid'"
                        v-model="form.country"
                        :options="countries"
                        :reduce="item => item.id"
                        label="name"
                        placeholder="Country*"
                        @input="onSelectCountry"
              ></v-select>
              <div class="error" v-if="$v.form.country.$error">
                Country is required
              </div>
            </b-form-group>
            <b-form-group v-if="states.length">
              <v-select class="state-select selection"
                        :class="$v.form.state.$error ?  'invalid' : 'valid'"
                        v-model="form.state"
                        :options="states"
                        :reduce="item => item.id"
                        label="name"
                        placeholder="State*"
                        @input="onSelectState"
              ></v-select>
              <div class="error" v-if="$v.form.state.$error">
                State is required
              </div>
            </b-form-group>
            <b-form-group v-if="counties.length">
              <v-select class="county-select selection"
                        v-model="form.county"
                        :options="counties"
                        :reduce="item => item.id"
                        label="name"
                        placeholder="County"
              ></v-select>
            </b-form-group>
            <b-form-group v-if="cities.length">
              <v-select class="city-select selection"
                        :class="$v.form.city.$error ?  'invalid' : 'valid'"
                        v-model="form.city"
                        :options="cities"
                        :reduce="item => item.id"
                        label="name"
                        placeholder="City*"
                        @input="onSelectCity"
              ></v-select>
              <div class="error" v-if="$v.form.city.$error">
                City is required
              </div>
            </b-form-group>
            <b-form-group v-if="schoolDistricts.length">
              <v-select class="form-control school-district-select"
                        v-model="form.schoolDistrict"
                        :options="schoolDistricts"
                        :reduce="item => item.id"
                        label="name"
                        placeholder="School District"
              ></v-select>
            </b-form-group>
            <b-form-group>
              <b-form-input id="postalCode"
                            type="text"
                            aria-describedby="postal_code"
                            autocomplete="off"
                            placeholder="Postal Code"
                            :state="!$v.form.postalCode.$error"
                            @input="$v.form.postalCode.$touch()"
                            v-model="form.postalCode"
                            @focusout="sanitizeWhitespace('postalCode',
                              form)">
              </b-form-input>
              <b-form-invalid-feedback id="postalCode">
                <span v-if="!$v.form.postalCode.numeric">
                  Postal Code should be Numeric
                </span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </div>
      </div>

      <!-- Footer -->
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-12 my-auto">
          <div class="left">
            <small class="required text-left">
              <i>*Required fields</i>
            </small>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 col-12">
          <div class="right">
            <b-button class="btn btn-success mx-2 btn-h3-small"
                      @click="submit"
                      :disabled="$v.$invalid || !editedForm">
              Submit
            </b-button>
            <b-button class="btn btn-danger mx-2 btn-h3-small ml-2"
                      @click="$emit('close')">
              Cancel
            </b-button>
          </div>
        </div>
      </div>

      <!-- Loading -->
      <loading :active.sync="isLoading"
               :is-full-page="true"
               background-color="#000"
               color="#068bcd"
               :opacity="0.5"
               :width="70"
               :height="70">
        <rainbow-loader></rainbow-loader>
      </loading>
    </div>
  </div>
</template>

<script>
  import 'vue-croppa/dist/vue-croppa.css';
  import { required, numeric } from 'vuelidate/lib/validators';
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import ProfanityMixin from '../../mixins/ProfanityMixin';

  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');

  export default {
    name   : 'EditSchoolModal',
    mixins : [
      BreakpointMixin,
      ProfanityMixin,
    ],
    props : {
      school : Object,
    },
    data() {
      return {
        isLoading       : false,
        file            : null,
        placeholder     : 'Choose an image*',
        initialLogo     : null,
        currentLogo     : this.logo,
        oldLogo         : null,
        hideButton      : true,
        countries       : [],
        states          : [],
        counties        : [],
        schoolDistricts : [],
        cities          : [],
        form            : {
          schoolId       : null,
          schoolName     : null,
          address1       : null,
          address2       : null,
          country        : null,
          state          : null,
          county         : null,
          city           : null,
          schoolDistrict : null,
          postalCode     : null,
        },
        defaultLogos : [],
        mainProps    : {
          width  : 75,
          height : 75,
          class  : 'm1',
        },
        fileChosen     : false,
        safeSchoolName : true,
        safeAddress1   : true,
        safeAddress2   : true,
        showCroppa     : true,
        timestamp      : Date.now(),
        fallbackLogo   : 'https://s3-us-west-2.amazonaws.com/h3-admin-' +
          'bucket/school/default.png',
        onLoadLogo : null,
        editedForm : false,
      };
    },
    components : {
      Loading,
      RainbowLoader,
    },
    watch : {
      currentLogo(value) {
        if (value){
          if (value !== this.oldLogo)
            this.editedForm = true;
          else
            this.editedForm = false;
        }
      },
    },
    methods : {

      /**
       * Get Default Logos
       */
      getDefaultLogos() {
        this.$http.get('api/school/logos')
          .then((res) => {
            this.defaultLogos = res.data;
          }).catch(() => {});
      },

      /**
       * Get Countries
       */
      getCountries() {
        this.$http.get('api/country/list')
          .then((res) => {
            this.countries = res.data;
          }).catch(() => {
            this.countries = [];
          });
      },

      /**
       * Get States
       * @param countryId
       */
      getStates(countryId) {
        this.$http.get('api/state/by_country', {
          params : { id : countryId },
        }).then((res) => {
          this.states = res.data;
        }).catch(() => {
          this.states = [];
        });
      },

      /**
       * Get Counties
       * @param stateId
       */
      getCounties(stateId) {
        this.$http.get('api/county/by_state', {
          params : { id : stateId },
        }).then((res) => {
          this.counties = res.data;
        }).catch(() => {
          this.counties = [];
        });
      },

      
      /**
       * Get Cities
       * @param stateId
       */
      getCities(stateId) {
        this.$http.get('api/city/by_state', {
          params : { id : stateId },
        }).then((res) => {
          this.cities = res.data;
        }).catch(() => {
          this.cities = [];
        });
      },

      /**
       * Get School Districts
       * @param cityId
       */
      getSchooDistricts(cityId) {
        this.$http.get('api/school_district/by_city', {
          params : { id : cityId },
        }).then((res) => {
          this.schoolDistricts = res.data;
        }).catch(() => {
          this.schoolDistricts = [];
        });
      },

      /**
       * Update states on country update
       * @param id
       */
      onSelectCountry(id) {
        this.$v.form.country.$touch();
        this.$http.get('api/state/by_country', {
          params : {
            id,
          },
        })
          .then((res) => {
            this.states = res.data;
          })
          .catch(() => {
            this.states = [];
          });

        this.counties = [];
        this.cities = [];
        this.schoolDistricts = [];

        this.form.state = null;
        this.form.county = null;
        this.form.city = null;
        this.form.postalCode = "";
        this.form.schoolDistrict = null;
      },

      /**
       * Update counties and cities on state update
       * @param id
       */
      onSelectState(id) {
        this.$v.form.state.$touch();
        this.$http.get('api/city/by_state', {
          params : {
            id,
          },
        })
          .then((res) => {
            this.cities = res.data;
          })
          .catch(() => {
            this.cities = [];
          });

        this.$http.get('api/county/by_state', {
          params : {
            id,
          },
        })
          .then((res) => {
            this.counties = res.data;
          })
          .catch(() => {
            this.counties = [];
          });

        this.schoolDistricts = [];
        this.form.county = null;
        this.form.city = null;
        this.form.postalCode = "";
        this.form.schoolDistrict = null;
      },

      /**
       * Update school districts on city update
       * @param id
       */
      onSelectCity(id) {
        this.$v.form.city.$touch();
        this.$http.get('api/school_district/by_city', {
          params : {
            id,
          },
        })
          .then((res) => {
            this.schoolDistricts = res.data;
          })
          .catch(() => {
            this.schoolDistricts = [];
          });

        this.form.postalCode = "";
        this.form.schoolDistrict = null;
      },

      /**
       * Update School Logo using Default Image
       * @param assetUrl
       */
      useDefaultLogo(assetUrl) {
        this.showCroppa = false;
        this.timestamp = Date.now();
        this.currentLogo = assetUrl;
        this.showCroppa = true;
        this.initialLogo = null;
      },

      /**
       * Submit Form
       */
      submit() {
        var logoUrl = this.fallbackLogo;
        this.isLoading = true;
        
        if(this.fallbackLogo == this.onLoadLogo)
          logoUrl = null;
        else if(this.currentLogo)
          logoUrl = this.currentLogo;
        else
          logoUrl = this.fallbackLogo;

        const data = {
          // eslint-disable-next-line camelcase
          school_name        : this.form.schoolName,
          address1           : this.form.address1,
          address2           : this.form.address2,
          // eslint-disable-next-line camelcase
          country_id         : this.form.country || null,
          // eslint-disable-next-line camelcase
          state_id           : this.form.state || null,
          // eslint-disable-next-line camelcase
          county_id          : this.form.county || null,
          // eslint-disable-next-line camelcase
          city_id            : this.form.city || null,
          // eslint-disable-next-line camelcase
          school_district_id : this.form.schoolDistrict || null,
          // eslint-disable-next-line camelcase
          postal_code        : this.form.postalCode,
          // eslint-disable-next-line camelcase
          logo_url           : logoUrl,
        };
        
        this.$http.put('api/school', {
          schoolId     : this.form.schoolId,
          updateFields : data,
        }).then(() => {
          if (this.fileChosen)
            this.uploadFile();
          else {
            const that = this;
            setTimeout(async function () {
              that.isLoading = true;
              that.$notify({
                group : 'notif',
                type  : 'success',
                title : 'Success',
                text  : 'Updated School Info Successfully!',
              });
              that.$emit('close');
            }, 2000);
          }
        }).catch(() => {
          this.isLoading = false;
          this.$notify({
            group : 'notif',
            type  : 'error',
            title : 'Failed!',
            text  : 'Oops! Something went wrong!',
          });
        });
      },
      
      /**
       * Reset Selected Logo
       */
      resetLogo(){
        this.currentLogo = null;
        this.initialLogo = null;
        this.fileChosen = false;
      },

      /**
       * Upload File
       */
      async uploadFile() {
        this.file.generateBlob(async (blob) => {
          const formData = new FormData();
          formData.append('file', blob, 'image.jpg');
          formData.append('imageType', 'school');
          formData.append('id', this.school.school_id);

          try {
            await this.$http.post('/api/upload/image', formData, {});

            const that = this;
            setTimeout(async function () {
              that.isLoading = true;
              that.$notify({
                group : 'notif',
                type  : 'success',
                title : 'Success',
                text  : 'Updated School Info Successfully.',
              });
              that.$emit('close');
            }, 2000);
          } catch (err) {
            this.isLoading = false;
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed!',
              text  : 'Oops! Something went wrong!',
            });
          }
        }, 'image/jpeg');
      },

      /**
       * Disable button on image croppa update
       *
       * @param status
       */
      imageUpdate(status) {
        if (status === 'new') {
          this.hideButton = false;
          this.fileChosen = true;
          this.editedForm = true;
        } else {
          this.hideButton = true;
          this.file.refresh();
          this.fileChosen = false;
        }
        this.currentLogo = null;
        this.initialLogo = null;
      },

      /**
       * Check if File type is valid
       */
      onFileTypeMismatch() {
        this.placeholder = 'Invalid file type';
      },

      /**
       * Additional Form Validation
       */
      formUpdated(newValue) {
        if (newValue.schoolName != this.school.school_name ||
          newValue.address1 != this.school.address1 ||
          newValue.address2 != this.school.address2 ||
          newValue.country != this.school.country_id ||
          newValue.state != this.school.state_id ||
          newValue.county != this.school.county_id ||
          newValue.city != this.school.city_id ||
          newValue.schoolDistrict != this.school.school_district_id ||
          newValue.postalCode != this.school.postal_code ||
          this.fileChosen)
          this.editedForm = true;
        else
          this.editedForm = false;
      },
    },
    created() {
      this.getDefaultLogos();
      this.$watch('form', this.formUpdated, {
        deep : true,
      });
    },
    mounted() {
      this.showCroppa = false;
      this.initialLogo = this.onLoadLogo = this.school.logo_url || null;
      this.currentLogo = this.school.logo_url ||
        process.env.VUE_APP_ADMIN_BUCKET_BASE_URL + 'school/default.png';
      this.showCroppa = true;
      this.oldLogo = this.currentLogo;
      this.getCountries();

      if (this.school.country_id)
        this.getStates(this.school.country_id);

      if (this.school.state_id) {
        this.getCountries(this.school.state_id);
        this.getCities(this.school.state_id);
      }

      if (this.school.city_id)
        this.getSchooDistricts(this.school.city_id);

      this.form = {
        schoolId       : this.school.school_id,
        schoolName     : this.school.school_name,
        address1       : this.school.address1,
        address2       : this.school.address2,
        country        : this.school.country_id,
        state          : this.school.state_id,
        county         : this.school.county_id,
        city           : this.school.city_id,
        schoolDistrict : this.school.school_district_id,
        postalCode     : this.school.postal_code,
      };
    },
    validations() {
      return {
        file        : (this.currentLogo !== null) ? {} : { required },
        currentLogo : (this.fileChosen) ? {} : { required },
        form        : {
          schoolName : {
            required,
            minLength : 2,
            safeSchoolName() {
              return this.safeSchoolName;
            },
          },
          address1 : {
            safeAddress1() {
              return this.safeAddress1;
            },
          },
          address2 : {
            safeAddress2() {
              return this.safeAddress2;
            },
          },
          country    : { required },
          state      : (this.states.length > 0) ? { required } : {},
          city       : (this.cities.length > 0) ? { required } : {},
          postalCode : { numeric },
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/modals/edit-school";
</style>